body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.qr-code-box{
  border : 10px solid black;
  padding: 1rem;
  border-radius: 4px;
}

.rasoi-page{
  background-color: black;
  color: yellow;
  margin: 0;
}
main, h1{
  margin: 0;
}
.h1{
  padding: 1rem;
}
.main-center{
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.brand-text {
  font-weight: bolder;
  color:#367BEE;
  border: 1px solid rgb(212, 212, 212);
  padding: 4px 8px;
  border-radius: 4px;
  font-size:14px;
}